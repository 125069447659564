import { useEffect, useState, useRef } from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/new/star.png";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Second = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.second, "mb100", ""].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <h2 className={[classes.secondRowLeftTitle, "font-40"].join(" ")}>
                We are Your Solution
              </h2>
              <p
                className={[classes.secondRowLeftText, "font-16-300"].join(" ")}
              >
                We understand that your time is valuable, that’s why we created
                Work Up Club to simplify the process of finding professionals
                ready to complete tasks at a high level of quality.
              </p>
              <p
                className={[classes.secondRowLeftText, "font-16-300"].join(" ")}
              >
                Our professionals offer a broad range of services including:
              </p>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Completion of surveys
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Create your survey and get result by performer’s completing
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Social media activity
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Get new clients with the feedback for your business account
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Product testing
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Are you seeking to have your new product tested and
                  professionally estimated ? Create a/b testing and enjoy it
                  done by our performers
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Video watching
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Get a hot number of your video views with us.
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Audio transcription
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Transform your audio in the text quickly and professionally.
                </p>
              </div>
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Data entry
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Need real people registered on your platform? Just set a task
                  on Work Up Club!
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Internet research
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Do you need data organized and analyzed on specific theme?
                  Don’t wait and create a campaign!
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Email correspondence
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Activate business email with us.
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Trial subscriptions
                </p>
                <p
                  className={[
                    classes.secondRowLeftItemText,
                    "font-16-300",
                  ].join(" ")}
                >
                  Attract new clients with setting a task on Work Up Club.
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  and much more;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
