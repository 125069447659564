import { useEffect, useState, useRef } from "react";
import classes from "./Six.module.scss";
import img1 from "../../../assets/img/new/5/1.png";
import img2 from "../../../assets/img/new/5/2.png";

import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Six = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <div
                className={[classes.secondRowLeftTitle, "font-40"].join(" ")}
              >
                Our Instruments
              </div>
              <div
                className={[classes.secondRowLeftText, "font-16-300"].join(" ")}
              >
                At the WorkUpClub, we understand the challenges facing
                businesses and its marketers in the crowded, competitive digital
                world. That's why we offer a range of services and solutions
                designed to help you stand out from the crowd and achieve
                promotional goals.
              </div>
              <div
                className={[classes.secondRowLeftBotText, "font-24"].join(" ")}
              >
                Contact the WorkUpClub today to learn more about our services
                and how we can help you succeed online!
              </div>
              <div className={[classes.secondRowLeftBtn, ""].join(" ")}>
                <Button>Join Now!</Button>
              </div>
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <div
                className={[classes.secondRowLeftTitle, "font-40"].join(" ")}
              >
                With Us You Will :
              </div>
              <div className={[classes.secondRowRightColumn, ""].join(" ")}>
                <div
                  className={[classes.secondRowRightColumnLeft, ""].join(" ")}
                >
                  <div
                    className={[
                      classes.secondRowRightColumnLeftTitle,
                      "font-24",
                    ].join(" ")}
                  >
                    Increase your traffic
                  </div>
                  <div
                    className={[
                      classes.secondRowRightColumnLeftSubTitle,
                      "font-16-300",
                    ].join(" ")}
                  >
                    We'll help you attract new visitors through campaigns done
                    by our performers.
                  </div>
                </div>
                <div
                  className={[classes.secondRowRightColumnRight, ""].join(" ")}
                >
                  <img src={img1} alt="" />
                </div>
              </div>
              <div className={[classes.secondRowRightColumn, ""].join(" ")}>
                <div
                  className={[classes.secondRowRightColumnLeft, ""].join(" ")}
                >
                  <div
                    className={[
                      classes.secondRowRightColumnLeftTitle,
                      "font-24",
                    ].join(" ")}
                  >
                    Improve your engagement
                  </div>
                  <div
                    className={[
                      classes.secondRowRightColumnLeftSubTitle,
                      "font-16-300",
                    ].join(" ")}
                  >
                    Our performers will help you optimize your content and user
                    experience to keep visitors engaged and coming back for
                    more.
                  </div>
                </div>
                <div
                  className={[classes.secondRowRightColumnRight, ""].join(" ")}
                >
                  <img src={img2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
