import { useEffect, useState, useRef } from "react";
import classes from "./Four.module.scss";
import img1 from "../../../assets/img/new/3/1.png";
import img2 from "../../../assets/img/new/3/2.png";
import img3 from "../../../assets/img/new/3/3.png";
import img4 from "../../../assets/img/new/3/4.png";
import img5 from "../../../assets/img/new/3/5.png";
import img6 from "../../../assets/img/new/3/6.png";

import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Four = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.second, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondTitle, "font-48"].join(" ")}>
            Our Values
          </div>

          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <div
                className={[classes.secondRowColumnText, "font-24"].join(" ")}
              >
                Convenient task <br /> creation
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <div
                className={[classes.secondRowColumnText, "font-24"].join(" ")}
              >
                Wide range of
                <br /> services
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <div
                className={[classes.secondRowColumnText, "font-24"].join(" ")}
              >
                Guaranteed
                <br /> quality work
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img4} alt="" />
              </div>
              <div
                className={[classes.secondRowColumnText, "font-24"].join(" ")}
              >
                Experienced and
                <br /> verified professionals
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img5} alt="" />
              </div>
              <div
                className={[classes.secondRowColumnText, "font-24"].join(" ")}
              >
                Cost-effective
                <br /> solutions
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <img src={img6} alt="" />
              </div>
              <div
                className={[classes.secondRowColumnText, "font-24"].join(" ")}
              >
                Time-saving
                <br /> benefits
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
