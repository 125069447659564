import { useEffect, useState, useRef } from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/new/star.png";
import img2 from "../../../assets/img/new/2/1.webp";

import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Three = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb100", ""].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <p
                className={[classes.secondRowLeftText, "font-16-300"].join(" ")}
              >
                One of our key principles is guaranteed quality work. We
                carefully select our professionals, verify their
                professionalism, and evaluate their work results. You can be
                confident that every task will be completed on time and to a
                high standard.
              </p>
              <p
                className={[classes.secondRowLeftText, "font-16-300"].join(" ")}
              >
                Our platform is convenient and easy to use. You can easily
                create a task by describing your requirements and defining a
                budget. After that, you will receive proposals from our
                experienced professionals and choose the one who best suits your
                project.
              </p>
              <p
                className={[classes.secondRowLeftText, "font-16-300"].join(" ")}
              >
                Working with us guarantees quality work, reduces the cost of
                finding and hiring professionals, and saves time in completing
                tasks. Our platform will help you achieve your business goals
                faster and more efficiently than ever before.
              </p>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Completion of surveys
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Social media activity
                </p>
              </div>
              <div className={[classes.secondRowLeftItem, ""].join(" ")}>
                <p
                  className={[classes.secondRowLeftItemTitle, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img1} alt="" />
                  Product testing
                </p>
              </div>
              <div
                className={[classes.secondRowLeftBotText, "font-24"].join(" ")}
              >
                Get your promotional success quickly with our low-cost task
                completion services!
              </div>
              <div className={[classes.secondRowLeftBtn, ""].join(" ")}>
                <Button>Join Now!</Button>
              </div>
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
