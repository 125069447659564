import React, { useEffect } from "react";
import img1 from "../../../assets/img/new/4/1.png";
import img2 from "../../../assets/img/new/4/2.png";
import img3 from "../../../assets/img/new/4/3.png";
import img4 from "../../../assets/img/new/4/4.png";
import img5 from "../../../assets/img/new/4/5.png";
import img6 from "../../../assets/img/new/4/6.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../../assets/img/HomePage/slider/left.png";
import imgRight from "../../../assets/img/HomePage/slider/right.png";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
// import { Fab } from "@material-ui/core";
// import testlefticon from "../assets/pictures/testlefticon.svg"
// import testrightiicon from "../assets/pictures/testrightiicon.svg"

import "./styles.scss";
import { Slide } from "./testimonial.js";
// import pic1 from "../assets/pictures/our-clients/pic1.png"
// import pic2 from "../assets/pictures/our-clients/pic2.png"
// import pic3 from "../assets/pictures/our-clients/pic3.png"
// import pic4 from "../assets/pictures/our-clients/pic4.png"

const data = [
  {
    image: img1,
    title: "Social media advertising",
    text: "The client asks performers to publish posts or advertisements on social media pages, such as Facebook or Instagram, with a link to the client's website. Also, clients can boost the page with likes and comments if the customer sets such a task. This can lead to a high number of clicks to the website.",
  },
  {
    image: img2,
    title: "Internet research marketing",
    text: "The client asks the performers to find and post content related to the client's website topic on other websites, forums, blogs, etc. This can lead to a high click-through rate to the client's site if the content is of interest to the audience and contains a link to the client's site.",
  },
  {
    image: img3,
    title: "Video marketing",
    text: "The customer asks performers to view videos on YouTube or other video hosting platforms that link back to the customer's site. If the video is compelling and interesting to the audience, it can get a lot of views and drive a lot of traffic to the site.",
  },
  {
    image: img4,
    title: "Email marketing",
    text: "The customer asks performers to collect the email addresses of potential customers and send them a newsletter inviting them to visit the customer's site. If the newsletter is interesting and contains relevant information, it can attract a lot of traffic to the site.",
  },
  {
    image: img5,
    title: "Product marketing",
    text: "The customer asks performers to test a new product — performers test and give a feedback for it. This process can increase interest in the product and therefore increase traffic to the website or product page. ",
  },
  {
    image: img6,
    title: "Data entry marketing",
    text: "The performer provides the customer with the report that shows the results of the data entry tasks. Data entry marketing can be an effective strategy for performers to increase traffic to a website or webpage. ",
  },
];

const Testimonial = () => {
  const ref = React.useRef(StackedCarousel);
  useEffect(() => {
    // setInterval(stuff, 6000);
    // console.log("i fire once");
  }, []);

  function stuff() {
    ref.current?.goNext();
  }

  return (
    <div className={[classes.slider, ""].join(" ")}>
      <h3 className={[classes.title, "font-48"].join(" ")}>
        Cases : How Does it Work
      </h3>

      <div className="card card-carrier">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1, 0.65,

                    0.8,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className={[classes.arrows, ""].join(" ")}>
            <div
              className={[classes.arrowsLeft, ""].join(" ")}
              onClick={() => ref.current?.goBack()}
            >
              <img src={imgLeft} alt="" />
            </div>
            <div
              className={[classes.arrowsRight, ""].join(" ")}
              onClick={() => ref.current?.goNext()}
            >
              <img src={imgRight} alt="" />
            </div>
          </div>

          {/* <div
          className="card-button testimonial-left-button"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          left
        </div>
        <div
          className="card-button testimonial-right-button"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          right
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
