import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/new/1/1.png";
import img2 from "../../../assets/img/new/1/2.png";
import img3 from "../../../assets/img/new/1/3.png";
import img4 from "../../../assets/img/new/1/4.png";
import imgMob from "../../../assets/img/new/1/mob_1.webp";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const First = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);
  const monetRef4 = useRef(null);
  const monetRef5 = useRef(null);
  const monetRef6 = useRef(null);
  const monetRef7 = useRef(null);
  const leftImgRef = useRef(null);
  const leftImgRef1 = useRef(null);
  const imgsRef = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(leftImgRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top top",
          bottom: "top bottom",
          scrub: 6,
        },
        x: 300,
        y: -100,

        autoAlpha: 0.5,
      });
      gsap.timeline().to(imgsRef.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top top",
          bottom: "top bottom",
          scrub: 6,
        },
        x: 300,
        y: -50,
        scale: 0.7,
        autoAlpha: 0.5,
      });
      gsap.timeline().to(leftImgRef.current, {
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scale: 1,
        autoAlpha: 1,
        delay: 0.6,
      });
      gsap.timeline().to(monetRef2.current, {
        scale: 1,
        autoAlpha: 1,
        delay: 0.7,
      });
      gsap.timeline().to(monetRef3.current, {
        scale: 1,
        autoAlpha: 1,
        delay: 0.8,
      });
      gsap.timeline().to(monetRef4.current, {
        scale: 1,
        autoAlpha: 1,
        delay: 0.3,
        y: 0,
      });
      gsap.timeline().to(monetRef5.current, {
        scale: 1,
        autoAlpha: 1,
        delay: 1,
      });
      gsap.timeline().to(monetRef6.current, {
        scale: 1,
        autoAlpha: 1,
        delay: 1.1,
      });
      gsap.timeline().to(monetRef7.current, {
        y: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container" ref={col1}>
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <div className={[classes.firstInfo, ""].join(" ")}>
              <p className={[classes.firstInfoUpText, "font-40"].join(" ")}>
                WorkUpClub.
              </p>
              <h1 className={[classes.firstInfoTitle, "font-64"].join(" ")}>
                Fast. Efficient. Accessible.
              </h1>
              <p className={[classes.firstInfoSubtitle, "font-36"].join(" ")}>
                Reach the tops of your campaigns!
              </p>
              <p className={[classes.firstInfoText, "font-16-300"].join(" ")}>
                Are you struggling to get the traffic, engagement, and
                conversions on the Internet? Welcome to Work Up Club - a place
                where you can get high-quality help with promotional tasks for
                business. Our platform connects clients with thousands of
                verified and experienced professionals ready to assist with a
                wide range of tasks completed.
              </p>
              <p className={[classes.firstInfoText, "font-16"].join(" ")}>
                Maximize your promotion with Work Up Club! 
              </p>
              <div className={[classes.firstInfoBtn, ""].join(" ")}>
                <Button>Join Now!</Button>
              </div>
            </div>
            <div className={[classes.firstImgs, ""].join(" ")} ref={imgsRef}>
              <div
                className={[classes.firstImgsImg1, ""].join(" ")}
                ref={monetRef1}
              >
                <img src={img1} alt="you boost your online presence?" />
              </div>
              <div
                className={[classes.firstImgsImg2, ""].join(" ")}
                ref={monetRef2}
              >
                <img src={img2} alt="you boost your online presence?" />
              </div>
              <div
                className={[classes.firstImgsImg3, ""].join(" ")}
                ref={monetRef3}
              >
                <img src={img3} alt="you boost your online presence?" />
              </div>
              <div
                className={[classes.firstImgsImg4, ""].join(" ")}
                ref={monetRef4}
              >
                <img src={img4} alt="you boost your online presence?" />
              </div>
            </div>
          </div>
        </div>
        <div className={[classes.firstMob, ""].join(" ")}>
          <img src={imgMob} alt="" />
        </div>
      </div>
    </div>
  );
};

export default First;
