import { useEffect, useState, useRef } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import First from "../../components/HomePage/First/First";
import Second from "../../components/HomePage/Second/Second";
import Three from "../../components/HomePage/Three/Three";
import Four from "../../components/HomePage/Four/Four";
import Six from "../../components/HomePage/Six/Six";
import Seven from "../../components/HomePage/Seven/Seven";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Slider from "../../components/HomePage/Slider/Slider";
import Test from "../../components/HomePage/Test/Slider";
import SecondFooter from "../../components/GlobalComponents/SecondFooter/SecondFooter";

const HomePage = () => {
  const [width, setWidth] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div>
      <div className="ovf-hidden">
        <div className="pageBg"></div>

        <div className="content">
          <Header />
          <Popup />
          <First />
          <Second />
          <Three />
          <Four />
          <Test />
          <Six />
          <Seven />
          <Slider />
          <SecondFooter />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
